import React, { useContext, useEffect, useState } from 'react';
import { AgentContext } from '../contexts/AgentContext';
import { db } from '../helpers/firebase';
import Modal from 'react-modal';
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + ' - ' + match[4];
    }
    return phoneNumberString;
}

const Dashboard = () => {
    const { agentData, setAgentData } = useContext(AgentContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [callerData, setCallerData] = useState(null);
    const [isLoading, setIsLoading] = useState(!agentData);
    const navigate = useNavigate();

    useEffect(() => {
        const storedAgentData = localStorage.getItem('agentData');
        if (storedAgentData) {
            setAgentData(JSON.parse(storedAgentData));
        } else {
            navigate('/'); // redirect to login page
        }
    }, []);

    useEffect(() => {
        if (agentData) {
            setIsLoading(false);
            localStorage.setItem('agentData', JSON.stringify(agentData));
            localStorage.setItem('timestamp', Date.now());
        }

        let isFirstRun = true;
        const q = query(collection(doc(db, 'tenants', localStorage.getItem('tenantId')), 'agents_calls_forward_info'));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (isFirstRun) {
                isFirstRun = false;
                return;
            }

            snapshot.docChanges().forEach((change) => {
                //console.log('Received document data:', JSON.stringify(change.doc));
                if (change.type === 'added' && agentData.id === change.doc.data().agentId) {
                    console.log('AgentID matched data:', change.doc.data());
                    setCallerData({
                        customerName: change.doc.data().callRecord.customerName,
                        phoneNumber: change.doc.data().callRecord.phoneNumber,
                        voicemailUrl: change.doc.data().callRecord.voicemailUrl,
                        useCallBackCode: change.doc.data().callRecord.useCallBackCode,
                    });
                    setModalIsOpen(true);
                }
            });
        });
        /*setCallerData({
            customerName: "John Doe",
            phoneNumber: "+11234567890",
            voicemailUrl: "",
            useCallBackCode: 1234,
        });
        setModalIsOpen(true);*/
        return () => unsubscribe();
    }, [agentData]);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ fontFamily: 'Encode Sans, sans-serif', height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <div style={{ backgroundColor: '#3979F5', padding: '10px', color: '#fff', fontWeight: 500, fontSize: '20px' }}>
                WeCare Connect
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 30px', flexShrink: 0 }}>
                <h2 style={{ color: '#333', fontSize: '2em', margin: 0 }}>Agent Console</h2>
                <button
                    onClick={handleLogout}
                    style={{
                        padding: '0',
                        fontSize: '1em',
                        backgroundColor: 'transparent',
                        color: '#3979F5',
                        border: '1px solid transparent',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                        margin: 0
                    }}
                >
                    Logout
                </button>
            </div>
            <div style={{ padding: '10px', flexGrow: 1, overflowY: 'auto', margin: '0 20px' }}>
            <div style={{
                    backgroundColor: '#CCE5FF',
                    color: '#323338',
                    padding: '10px',
                    fontSize: '14px',
                    borderRadius: '5px',
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <i className="fas fa-circle-info" style={{ marginRight: '10px' }}></i>
                    Note: You are logged in with the credentials below. When the system connects to a person, a screen will pop-up here with the details of incoming caller.
                </div>
                <div style={{
                    backgroundColor: '#fff',
                    padding: '1em',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                    marginBottom: '20px'
                }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#fff', borderBottom: '2px solid #E6E9EF' }}>  {/* Added borderBottom to this <tr> */}
                                <th style={{ padding: '0.5em', textAlign: 'left', fontWeight: 'bold', width: '15%' }}>Agent Name</th>
                                <th style={{ padding: '0.5em', textAlign: 'left', fontWeight: 'bold', width: '15%' }}>ID</th>
                                <th style={{ padding: '0.5em', textAlign: 'left', fontWeight: 'bold', width: '20%' }}>Phone Number</th>
                                <th style={{ padding: '0.5em', textAlign: 'left', fontWeight: 'bold', width: '15%' }}>Extension</th>
                                <th style={{ padding: '0.5em', textAlign: 'left', fontWeight: 'bold', width: '35%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ backgroundColor: '#fff' }}>
                                <td style={{ padding: '0.5em' }}>{agentData.firstName} {agentData.lastName}</td>
                                <td style={{ padding: '0.5em' }}>{agentData.id}</td>
                                <td style={{ padding: '0.5em' }}>{formatPhoneNumber(agentData.phoneNumber)}</td>
                                <td style={{ padding: '0.5em' }}>{agentData.extension}</td>
                                <td style={{ padding: '0.5em' }}></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{
                    content: {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '80%', // Reduced width
                        maxWidth: '400px', // Reduced max width
                        height: 'auto',
                        maxHeight: '320px', // Reduced max height
                        margin: 'auto',
                        padding: '1em',
                        overflow: 'auto',
                        wordWrap: 'break-word',
                        fontFamily: 'Encode Sans, sans-serif',
                        fontSize: '1em' // Adjusted font size
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }}
            >
                <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', color: '#323338' }}>
                    <div style={{ fontSize: '32px', color: '#323338' }}>Hello {agentData.firstName} {agentData.lastName}</div>
                    <br /><br />You have an Incoming call from:
                    <br /><br /><br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em', fontSize: '16px', color: '#323338' }}>
                        <span>Respondent:</span> <span>{callerData?.customerName}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em', fontSize: '16px', color: '#323338' }}>
                        <span>Phone Number:</span> <span>{formatPhoneNumber(callerData?.phoneNumber)}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em', fontSize: '16px', color: '#323338' }}>
                        <span>Privacy Code:</span> <span>{callerData?.useCallBackCode}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
                    <button
                        onClick={() => setModalIsOpen(false)}
                        style={{
                            padding: '0.5em 1em',
                            fontSize: '1em',
                            backgroundColor: '#007BFF',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            fontFamily: 'Encode Sans, sans-serif' // Ensure font family is applied
                        }}
                    >
                        Close
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default Dashboard;